<template>
	<div style="height: 100vh;">
		<iframe v-if="ismap" id="mapPage" width="100%" height="100%" frameborder=0
			:src="url">
		</iframe>
		<div style="height: 1000px;" class="block" id="container"></div>
		</div>
</template>

<script>
export default {
	data() {

		return {
			url:'https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=CYWBZ-R5UKV-LFGPO-UDLQ2-E5DJ7-MHF7J&referer=凯迪销售助手&coord= + ',
			ss:'111',
			ismap:false,
			geolocation:null,
		}
	},
	mounted(){
		const that = this
		this.geolocation = new qq.maps.Geolocation('CYWBZ-R5UKV-LFGPO-UDLQ2-E5DJ7-MHF7J', '凯迪销售助手');
		// 2，并且开始定位
		this.$wx.getLocation({
				type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
				success: function (res) {
					alert(JSON.stringify(res))
					that.getMyLocation();
						// var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
						// var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
						// var speed = res.speed; // 速度，以米/每秒计
						// var accuracy = res.accuracy; // 位置精度
				}
		});

   
		window.addEventListener('message', (event) => {
				// 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
				var loc = event.data;
				if (loc && loc.module == 'locationPicker') {//防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
					loc = JSON.stringify(loc)
					this.$router.replace({path:'/stores-information?loc=' + loc})
				}
		}, false)
	},
	methods:{
	 // 获取当前位置
	 getMyLocation() {
      this.geolocation.getLocation(this.showPosition, this.errorPosition); //开启定位
    },
    // 定位成功
    showPosition(position) {
			alert(JSON.stringify(position))
      console.log('定位成功',position);  
			alert(position.lat + ',' + position.lng)
			this.url = this.url + position.lat + ',' + position.lng
      this.ismap = true
    },
    // 定位失败 继续尝试定位
    errorPosition(e) {
      console.log('定位失败，再次进行定位');
      // 此判断是防止多次绘制地图
      if(!this.map){
        this.getMyLocation(); 
      }
    },
	}
}

</script>

<style lang="scss" scoped>
</style>
